.productWrapper {
    background: linear-gradient(90deg, rgba(41, 22, 111, 0.2) 0%, rgba(41, 22, 111, 0) 21.12%), linear-gradient(270deg, rgba(41, 22, 111, 0.2) 0%, rgba(41, 22, 111, 0) 17.62%), rgba(0, 146, 221, 0.2);
}
.productHeader {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #201644;
}

.commonProductWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 70%;
}

@media (max-width:768px) {
    .productHeader {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #201644;
    }
}

@media (max-width:480px) {
    .productHeader {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #201644;
    }
}

@media (max-width:360px) {
    .productHeader {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #201644;
    }
}