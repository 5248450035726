.productWrapper {
    background: #FFFFFF;
    border-radius: 25px;
    margin: 15px;
    display: flex;
    cursor: pointer;
    box-shadow: 4px 4px 8px 0px rgba(89, 116, 130, 0.25);
}

.description2{
    color: #201644a9;
    font-family: 'Raleway';
    font-style: normal;
    font-size: 15px;
    vertical-align:top;
    line-height:20px;
    margin-top: 4%;
    margin-left: 4%;
}

.productName {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #201644;
    margin-top: 4%;
    margin-left: 4%;
    margin-right: 30px;
}

.watchText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #0092DD;
    margin-right: 12px;
}


@media (max-width:1700px) {
        .description2{
            font-size: 12px;
            line-height:16px;
        }
}

@media (max-width:1320px) {
    .description2{
        font-size: 10px;
        line-height:15px;
    }
}

@media (max-width:1070px) {
    .description2{
        font-size: 9px;
        line-height:13px;
    }
}

@media (max-width:800px) {
    .description2{
        font-size: 8px;
        line-height:10px;
    }
}

@media (max-width:500px) {
    .description2{
        font-size: 7px;
        line-height:9px;
    }
}

@media (max-width:300px) {
    .description2{
        font-size: 6px;
        line-height:8px;
    }
}



@media (max-width:768px) {
    .productName {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #201644;
    }
}

@media (max-width:480px) {
    .productName {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #201644;
    }
}

@media (max-width:360px) {
    .productName {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #201644;
    }
}