.buttonWrapper {
    background: #FFFFFF;
    border: 1px solid #3C8FE2;
    border-radius: 8px;
    margin: 10px;
    height: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.buttonWrapper:hover {
    background: #F0F8FF;
}

.buttonBox {
    border: 1px solid #3C8FE2;
    margin: 10px;
    border-radius: 8px;
}