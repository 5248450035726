.doubleSidedGradient {
    background: linear-gradient(90deg, rgba(41, 22, 111, 0.2) 0%, rgba(41, 22, 111, 0) 21.12%), linear-gradient(270deg, rgba(41, 22, 111, 0.2) 0%, rgba(41, 22, 111, 0) 17.62%), rgba(0, 146, 221, 0.2); 
    height: 45px;
    width: 100%;
    max-width: 1920px;
}

@media(max-width:1000px){
    .doubleSidedGradient {
        height: 30px;
    }
}

@media(max-width:768px){
    .doubleSidedGradient {
        height: 25px;
    }
}

@media(max-width:480px){
    .doubleSidedGradient {
        height: 20px;
    }
}

@media(max-width:360px){
    .doubleSidedGradient {
        height: 15px;
    }
}