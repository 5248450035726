.itemIcon{
    cursor:pointer;
    height: 35px;
    width: 35px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.closeIcon{
    cursor: pointer;
    height: 10px;
    width: 10px;
    position: absolute;
    margin-left: -5px;
}
.imageLinksEditorWrapper{
    display: flex;
    flex-direction: row;
}