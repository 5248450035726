.animStyle {
    display: flex;
    flex-direction: column;
    z-index: 10;
    background: #F6F6F6;
    transition: 1s;
}

.footer {
    margin-top: 80px;
}

/* Скрываем scrollbar для IE, Edge и Firefox */
.globalWrap {
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 1s;
}
