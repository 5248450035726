.justifier{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.order{
    width: 100%;
    max-width: 1920px;
    overflow: hidden;
}
.orderTint{
    width: 100%;
    max-width: 1920px;
    position: absolute;
    height: calc(84.7825px + 1.52vw);
    background: rgba(216, 216, 216, 0.4);
    z-index: -1;
}
.orderSeparatorWrapper{
    display: flex;
    justify-content: center;
}
.orderSeparator{
    border-bottom: 1px solid #0092DD;
    width: calc(20.76vw + 711.39px);
    height: 0px;
    margin-top: calc(83.7825px + 1.52vw);
}
.orderHeaderWrapper{
    display: flex;
    justify-content: center;
}
.orderHeader{
    position: absolute;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #201644;
    z-index: 2;
    margin-top: 74px;
    width: calc(20.76vw + 711.39px);
}
.orderText{
    width: calc(20.76vw + 711.39px);
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-top: 40px;
    color: #201644;
    text-align: justify;
}
.orderCross{
    display: flex;
    margin-left: calc(34.78vw - 527.826px);
    margin-top: -102px;
    z-index: 0;
}
.orderButtons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-top: -92px;
    margin-bottom: 70px;
    width: calc(20.76vw + 711.39px);
}
.orderArrowBox{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    width: calc(20.76vw + 711.39px);
}
.orderArrowText{
    margin-left: 15px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #201644;
    text-align: justify;
}
.orderLowerText{
    z-index: 1;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #201644;
    margin-top: 41px;
    text-align: justify;
    width: calc(20.76vw + 711.39px);
}
.orderBottomTint{
    width: 100%;
    background: rgba(216, 216, 216, 0.4);
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.orderWarningContent{
    display: flex;
    flex-direction: row;
    width: calc(20.76vw + 711.39px);
    margin-top: 3%;
    margin-bottom: 3%;
}
.orderWarningText{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    color: #201644;
    
    margin-left: 19px;
    text-align: justify;
}
.orderExclamation{
}

@media(min-width:1920px) {
    .orderText {
        width: 1110px;
    }
    .orderSeparator {
        width: 1110px;
        margin-top: 113px;
    }
    .orderHeader{
        width: 1110px;
    }
    .orderCross{
        margin-left: 140px;
    }
    .orderTint{
        max-width: 1920px;
        height: 114px;
    }
    .orderArrowBox{
        width: 1110px;
    }
    .orderLowerText{
        width: 1110px;
    }
    .orderButtons{
        width: 1110px;
    }
    .orderWarningContent{
        width: 1110px;
    }
}

@media(max-width:1000px) {
    .orderText {
        width: calc(124.138vw - 322.38px);
    }
    .orderSeparator {
        width: calc(124.138vw - 322.38px);
        margin-top: calc(51.586px + 4.74vw);;
    }
    .orderHeader{
        margin-top: 50px;
        width: calc(124.138vw - 322.38px);
    }
    .orderCross{
        margin-left: -180px;
    }
    .orderTint{
        height: calc(52.586px + 4.74vw);
    }
    .orderArrowBox{
        width: calc(124.138vw - 322.38px);
    }
    .orderLowerText{
        width: calc(124.138vw - 322.38px);
    }
    .orderButtons{
        width: calc(124.138vw - 322.38px);
    }
    .orderWarningContent{
        width: calc(124.138vw - 322.38px);
    }
}

@media(max-width:768px) {
    .orderText {
        width: calc(64.5833vw + 135px);
        font-size: 15px;
    }
    .orderSeparator {
        width: calc(64.5833vw + 135px);
        margin-top: calc(104px - 2.083vw);
    }
    .orderHeader{
        margin-top: 34px;
        font-size: 18px;
        width: calc(64.5833vw + 135px);
    }
    .orderCross{
        width: calc(197.916vw - 125px);
        margin-left: calc(16.133px - 25.538vw);
    }
    .orderTint{
        height: calc(105px - 2.083vw);
    }
    .orderArrowBox{
        width: calc(64.5833vw + 135px);
    }
    .orderArrowText{
        font-size: 15px;
    }
    .orderLowerText{
        width: calc(64.5833vw + 135px);
    }
    .orderButtons{
        width: calc(64.5833vw + 135px);
    }
    .orderWarningText{
        font-size: 12px;
    }
    .orderWarningContent{
        width: calc(64.5833vw + 135px);
    }
}

@media(max-width:915px){
    .orderButtons{
        flex-direction: column;
        margin-left: 0px;
        width: 100%;
    }
}

@media(max-width:480px) {
    .orderText {
        font-size: 13px;
        width: calc(91.66vw + 5px);
    }
    .orderSeparator {
        width: calc(91.66vw + 5px);
        margin-top: calc(2px + 19.66vw);
    }
    .orderHeader{
        margin-top: 35px;
        font-size: 17px;
        width: calc(91.66vw + 5px);
    }
    .orderTint{
        height: calc(3px + 19.66vw);
    }
    .orderArrowBox{
        width: calc(91.66vw + 5px);
    }
    .orderArrowText{
        font-size: 13px;
    }
    .orderLowerText{
        width: calc(91.66vw + 5px);
    }
    .orderWarningText{
        font-size: 11px;
    }
    .orderWarningContent{
        width: calc(91.66vw + 5px);
    }
}

@media(max-width:400px) {
    .orderCross{
        display: none
    }
    .orderButtons{
        margin-top: 40px;
    }
}

@media(max-width:360px) {
    .orderText {
        width: 335px;
    }
    .orderSeparator {
        width: 335px;
        margin-top: 71px;
    }
    .orderHeader{
        font-size: 16px;
        width: 335px;
    }
    .orderTint{
        height: 72px;
    }
    .orderArrowBox{
        width: 335px;
    }
    .orderLowerText{
        width: 335px;
    }
    .orderWarningContent{
        width: 335px;
    }
}

.kpWrapper {
    cursor: pointer;
    z-index: 400;
    position: fixed;
    right: max(2vmax, 40px);
    bottom: max(1.5vmax, 30px);
    border-radius: max(1vmax, 20px) max(0.25vmax, 5px) max(1vmax, 20px) max(1vmax, 20px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), #0092DD;
    display: inline-flex;
    padding: max(16px, 0.8vmax) max(20px, 1vmax);
    align-items: center;
    gap: 15px;
    user-select: none;
    width: max(1.3vmax, 26px);
    height: max(1vmax, 20px);
}