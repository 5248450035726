.itemIcon{
    cursor:pointer;
    height: 45px;
    width: 45px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.imageLinksEditorWrapper{
    display: flex;
    flex-direction: row;
}