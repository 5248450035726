
.wrapperPreLoader{
    width: 1290px; 
    position: relative; 
    margin-left:400px;
    position: relative;
}

.mainBoxPreloader {
    width: 90%;
    background: #639CD1;
    height: 195px;
    border-radius: 20px;
}

.blueBox{
    width: 90%;
    background: #ACCDEB;
    height: 100%;
    border-radius: 20px;
}

/* The animation code */
@keyframes preloader {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.darkLine{
    margin-left: 4%;
    height: 30%;
    width: 80%;
    background: #6A8BB1;
    border-radius: 20px;
    animation-name: preloader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.lightLine{
    margin-top: 40px;
    margin-left: 4.5%;
    height: 7%;
    width: 70%;
    background: #8CADD4;
    border-radius: 20px;
    animation-name: preloader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.greyLine{
    height: 20px;
    width: 80%;
    background: #DBDBDB;
    border-radius: 20px;
    margin-bottom: 10px;
    animation-name: preloader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@media(max-width:1920px) {
    .mainBoxPreloader {
        
    }
}



.wrapperPreLoader{
    width: 1290px; 
    position: relative; 
    position: relative;
}

.mainBoxPreloader {
    width: 100%;
    background: #639CD1;
    height: 195px;
    border-radius: 20px;
}

.blueBox{
    width: 90%;
    background: #ACCDEB;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.darkLine{
    margin-left: 4%;
    height: 30%;
    width: 80%;
    background: #6A8BB1;
    border-radius: 20px;
}

.lightLine{
    margin-top: 40px;
    margin-left: 4.5%;
    height: 7%;
    width: 70%;
    background: #8CADD4;
    border-radius: 20px;
}

.firstHeader{
    margin-left: 4%;
    width: 80%;
    font-size: 35px;
    font-weight: 700;
}

.regularText{
    margin-top: 20px;
    margin-left: 4%;
    width: 70%;
    font-size: 15px;
}

.greyLine{
    height: 20px;
    width: 95%;
    background: #DBDBDB;
    border-radius: 20px;
    margin-bottom: 10px;
}



@media(max-width:1920px) {
    .mainBoxPreloader {
        
    }
}

.closeButtonWrapper {
    position: absolute;
    top: 0;
    right: 0px;
    color: white;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -30px;
    top: 0px;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 1000px;
    cursor: pointer;
}

.closeButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}

.editButtonWrapper {
    position: absolute;
    color: black;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -30px;
    top: 30px;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 1000px;
    cursor: pointer;
}

.editButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}







.justifier {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.editorWrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
}

.paragraph {
    width: calc(20.76vw + 711.39px);
}

@media(min-width:1920px) {
    .paragraph {
        width: 1110px;
    }
}

@media(max-width:1000px) {
    .paragraph {
        width: calc(124.138vw - 322.38px);
    }

    .firstHeader{
        font-size: 25px;
    }
    
}

@media(max-width:768px) {
    .paragraph {
        width: calc(64.5833vw + 135px);
    }
    .firstHeader{
        font-size: 15px;
    }

    .regularText{
        font-size: 14px;
    }
}

@media(max-width:480px) {
    .paragraph {
        width: calc(91.66vw + 5px);
    }
}

@media(max-width:360px) {
    .paragraph {
        width: 335px;
    }
}

.headerProduct {

    display: flex;
}

.inputDescriptionStyle {
    Border: none;
    outline: none;
    color: #201644;
    /* mini */
    font-family: 'Verdana';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.inputNameStyle {
    Border: none;
    outline: none;
    color: var(--text, #201644);
    /* accent */
    font-family: 'Verdana';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.saveButton {
    background: #0092DD;
    padding: 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
    color:white
}

.saveButtonWrapper {
    display: flex;
    justify-content: center;
    color: white;
    margin-bottom: 30px;
}
.breadcrumbsStyle {
    flex: 1110px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0092DD;
    font-size: 13px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbsElement:hover{
    text-decoration: underline;
    cursor: pointer;
}