.shadowWrapper {
    background: rgb(0, 0, 0, 0.6);
    position: fixed;
    z-index: 1011;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    color: #201644;
    font-family: 'Raleway';
    font-size: max(20px, 1vmax);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-align: center;
    margin-top: 40px;
    
}

.alertWrapper {
    min-width: 600px;
    min-height: 200px;
    width: 30vmax;
    height: 10vmax;
    border-radius: max(0.5vmax, 10px);
    background: #F6F6F6;
    box-shadow: 0.1vmax 0.25vmax 0.5vmax 0px rgba(89, 116, 130, 0.25);
    position: relative;
}

.button {
    border-radius: 5px 5px 5px 5px;
    background: var(--accent, #0092DD);
    width: max(100px, 5vmax);
    height: max(50px, 2.5vmax);
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute;
    bottom: max(20px, 1vmax);
    right: max(40px, 2svmax); */
    color: #201644;
    font-family: 'Raleway';
    font-size: max(16px, 0.8vmax);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: white;
    cursor: pointer;
}

.buttonWrapper{
    display: flex;
    justify-content: center;
}

.button:hover {
    background: linear-gradient(180deg, rgba(23, 67, 90, 0.20) 0%, rgba(23, 67, 90, 0.00) 100%), #0092DD;
}

.marginAlert{
    height:max(120px, 6vmax);
}

@media (max-width:768px) {
    .alertWrapper {
        min-width: 350px;
        min-height: 120px;
        width: 300px;
        height: 100px;
        border-radius: max(0.5vmax, 10px);
        background: #F6F6F6;
        box-shadow: 0.1vmax 0.25vmax 0.5vmax 0px rgba(89, 116, 130, 0.25);
        position: relative;


    }

    .marginAlert{
        height:70px;
    }

    .text {
        font-size: 16px;
        margin-top: 20px;
    }

    .button {
        width: 80px;
        height: 40px;
        bottom: 15px;
        right: 20px;
    }
}