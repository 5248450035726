.polusWrapper {
    background: rgba(216, 216, 216, 0.4);
    flex: 1;
    border-radius: 25px 0px 0px 25px;
    position: relative;
}

.polusHeader {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #201644;
}

.polusList {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    max-width: 700px;
    color: #201644;
    flex-direction: row;
}

.listMarginTop {
    margin-top: 20px;
}

@media (max-width:1000px) {
    .listMarginTop {
        margin-top: 12px;
    }
    .polusList {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        max-width: 700px;
        color: #201644;
        flex-direction: row;
    }

    .polusHeader {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #201644;
        display: flex;
        justify-content: center;
    }
}

@media (max-width:768px) {
    .polusList {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        max-width: 700px;
        color: #201644;
        flex-direction: column;
    }
}

@media (max-width:480px) {
    .polusList {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
        max-width: 700px;
        color: #201644;
        flex-direction: column;
    }

    .polusWrapper {
        background: rgba(216, 216, 216, 0.4);
        flex: 1;
        border-radius: 0px 0px 0px 0px;
        position: relative;
    }

    .polusHeader {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #201644;
    }
}

@media (max-width:360px) {
    .polusList {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        max-width: 700px;
        color: #201644;
        flex-direction: column;
    }

    .polusHeader {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        color: #201644;
    }
}