.headerWrapper {
    margin-top: 10px;
    display: flex;
    width: 80%;
    height: 46px;
}

.searchWrapper {
    position: absolute;
    bottom: -50px;
    right: 0;
}

.searchWrapperMini {
    position: absolute;
    bottom: -45px;
    right: 0;
    z-index: 600;
}

.searchListWrapper {
    width: 90%;
    background: #F6F6F6;
    /* cards */
    box-shadow: 4px 4px 10px rgba(89, 116, 130, 0.25);
    border-radius: 0px 0px 25px 25px;
    margin-left: 10%;
    position: absolute;
    top: 20px;
    z-index: -1;
}

.searchListElementWrapper {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.searchListElementWrapper:hover {
    background: rgba(216, 216, 216, 0.4);
    cursor: pointer;
}

.searchListElementText1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* text */

    color: #201644;
}

.searchListElementText2 {
    font-family: 'Roboto-LightItalic';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: end;
    color: #9E9E9E;

}

.searchStyleMini {
    width: 400px;
    height: 40px;
    background: #F6F6F6;
    /* cards */
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 4px 4px 10px rgba(89, 116, 130, 0.25);
    border-radius: 25px 0px 0px 25px;
    border-width: 0;
    outline: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* text */

    color: #201644;
}


.searchStyleMini::placeholder {

    font-family: 'Roboto-LightItalic';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #9E9E9E;
}

.searchStyle {
    width: 400px;
    height: 40px;
    background: #F6F6F6;
    /* cards */
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 4px 4px 10px rgba(89, 116, 130, 0.25);
    border-radius: 25px;
    border-width: 0;
    outline: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* text */

    color: #201644;
    z-index: 999999;
}


.searchStyle::placeholder {
    font-family: 'Roboto-LightItalic';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #9E9E9E;
}


.menuWrapper {
    width: 360px;
    height: 454px;
    background: red;
    position: absolute;
    z-index: 3;
    right: 0;
    margin-top: 3px;
    background: #F6F6F6;
    border-radius: 0px 0px 0px 25px;
    transition: 0.3s;
}

.menuElement {
    width: 121px;
    height: 38px;
    border-radius: 25px 5px 25px 25px;
    margin-bottom: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.menuElement:hover {
    background: rgba(216, 216, 216, 0.4);
    text-decoration: none;
}

.menuElementText {
    text-decoration: none;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #201644;

}

.shadow {
    position: absolute;
    width: 100vw;
    background: rgba(216, 216, 216, 0.4);
    z-index: 3;
    border-radius: 0px;
    transition: 0.3s;
}

.headerElement {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    height: 38px;
    text-decoration: none;
    color: #201644;
    border-radius: 25px 5px 25px 25px;
    margin-left: 5px;
}

.headerElement:hover {
    text-decoration: none;
    background: rgba(216, 216, 216, 0.4);
}

.smallHeaderWrapper {
    background: white;
    width: 100%;
    border-bottom: solid #0092DD;

}

.textPhone {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(32, 22, 68, 0.9);
    margin-right: 50px;
}