.aboutPartnerBlock{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
}



.aboutPartnerBlockImageWrapper{
    width: 160px;
    display: flex;
    justify-content: center;
}

.aboutPartnerBlockImageWrapperWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutPartnerBlockImage{
    width: auto;
    object-fit: cover;
}

.aboutPartnerBlockText{
    margin-left: 30px;
    width: 635px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.2564102564102564vw + 11.076923076923077px);
    line-height: 130%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #201644;
}

@media (max-width:480px) {
    .aboutPartnerBlockText{
        margin-left: 0px;
    }
    .aboutPartnerBlock{
        flex-direction: column;
    }
    .aboutPartnerBlockText{
        font-weight: 50;
        width: 250px;
        margin-top: 10px;
    }

}