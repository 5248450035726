.addressBox{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.addressTextBoxContainer{
    display: flex;
    flex-direction: column;
    margin-left: calc(39.67vw - 356.739px);
}

.addressMapWrapper{
    height: calc(343.478px + 8.15vw);
    width:  calc(343.478px + 8.15vw);
    aspect-ratio: 1;
    margin-right: calc(37.5vw - 315px);
    filter: drop-shadow(4px 2px 15px rgba(89, 116, 130, 0.25));
    border-radius: 25px;
    overflow: hidden;
}

.addressHeaderText{
    color: #201644;
    margin-bottom: 30px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    z-index: 3;
}

.addressSidebar{
    margin-right: 44px;
}

.addressSidebarBox{
    display: flex;
    flex-direction: row;
}

.addressItemBox{
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    width: 320px;
}

.addressItem{
    color: #201644;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.addressItemText{
    color: #201644;
    font-family: 'Roboto-LightItalic';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin-left: 20px;
}

.addressItemPhoneText{
    font-family: 'Roboto-LightItalic';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    margin-left: 20px;
}

@media(min-width:1920px){
    .addressMapWrapper{
        height: 500px;
        width:  500px;
        margin-right: 405px;
    }
    .addressTextBoxContainer{
        margin-left: 405px;
    }
}

@media(max-width:1000px){
    .addressMapWrapper{
        width: calc(58.62px + 36.64vw);
        height: calc(58.62px + 36.64vw);
        margin-right: calc(34vw - 280.52px);
    }
    .addressTextBoxContainer{
        margin-left: calc(156.379px - 11.64vw);
    }
}

@media(max-width: 850px){
    .addressHeaderText{
        font-size: 18px;
        line-height: 21px;
    }
    .addressItemText{
        font-size: 14px;
        line-height: 130%;
    }
    .addressItemPhoneText{
        font-size: 18px;
        line-height: 130%;
    }
    .addressSidebar{
        margin-right: 30px;
    }
}

@media(max-width: 768px) {
    .addressItemBox{
        width: 250px;
    }
}

@media(max-width: 650px) {
    .addressHeaderText{
        font-size: 16px;
        line-height: 19px;
    }
    .addressBox{
        flex-direction: column;
    }
    .addressMapWrapper{
        width: auto;
        margin-left: 4.167%;
        height: calc(44px + 65.83vw);
    }
    .addressItemPhoneText{
        font-size: 16px;
        line-height: 130%;
    }
    .addressTextBoxContainer{
        margin-left: calc(14.167vw - 38px);
        margin-bottom: calc(70px - 8.33vw);
    }
}