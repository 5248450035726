.newsScrollBlock {
    background: #C5E2F1;
    margin: 1% 1.5% 2% 0px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.12);
    border-radius: 12% 3% 12% 12%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    aspect-ratio: 1/0.854;
    height: calc(247.468px + 6.746vw);
}

.newsImage {
    width: 80%;
    aspect-ratio: 1/0.66;
    border-radius: 2.34vw 0.26vw 2.34vw 2.34vw;
    margin: 10%;
    margin-top: 5%;
    margin-bottom: 3%;
    object-fit: cover;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.newsText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-left: 15%;
    margin-right: 10%;
    overflow: hidden;
    display: -webkit-box;
   -webkit-line-clamp: 4;
           line-clamp: 4; 
   -webkit-box-orient: vertical;
   transition: 0.5s;
}

.hoverBox{
    display: flex;
    flex-direction: row;
}

.newsHoverImage{
    width: 0px;
    height: 60px;
    margin-left: 0%;
    opacity: 0;
    transition: 0.5s;
}

.newsScrollBlock:hover .newsHoverImage{
    opacity: 1;
    margin-left: 10%;
    width: 40px;
}

.newsScrollBlock:hover .newsText{
    opacity: 1;
    margin-left: 5%;
}

@media(min-width:1920px){
    .newsImage{
        border-radius: 45px 5px 45px 45px;
    }
    .newsScrollBlock{
        height: 377px;
    }
}

@media(max-width:1000px){
    .newsScrollBlock{
        margin-bottom: 5%;
        height: calc(34.48vw - 29.8px);
        aspect-ratio: 1/0.8077;
    }
    .newsText{
        -webkit-line-clamp: 3;
           line-clamp: 3; 
    }
    .newsImage{
        aspect-ratio: 1/0.66;
    }
}

@media(max-width:900px){
    .newsText{
        font-size: 15px;
        line-height: 130%;
        -webkit-line-clamp: 2;
           line-clamp: 2;
        height: 40px; 
    }
    .newsHoverImage{
        height: 50px;
    }
}

@media(max-width:768px){
    .newsText{
        -webkit-line-clamp: 3;
           line-clamp: 3;
        height: 100%;
    }
    .newsScrollBlock{
        margin-bottom: 12%;
        height: calc(261.67px - 3.472vw);
        aspect-ratio: 1/0.839;
    }
    .newsImage{
        aspect-ratio: 1/0.565;
    }
    .newsHoverImage{
        display: none;
    }
    .newsText{
        margin-left: 15%;
        transition: none;
    }
    .newsScrollBlock:hover .newsText{
        opacity: 1;
        margin-left: 15%;
    }
}

@media(max-width:480px){
    .newsText{
        font-size: 13px;
        line-height: 130%;
    }
    .newsScrollBlock{
        margin-bottom: 15%;
        height: calc(165px - 16.67vw);
        aspect-ratio: 1/0.942;
        height: 90%;
    }
    .newsImage{
        aspect-ratio: 1/0.65;
    }
}

@media(max-width:360px){
    .newsText{
        font-size: 12px;
        line-height: 130%;
        -webkit-line-clamp: 4;
           line-clamp: 4; 
    }
    .newsScrollBlock{
        margin-bottom: 20%;
        aspect-ratio: 1/1.071;
    }
    .newsImage{
        aspect-ratio: 1/0.657;
    }
}