.aboutScrollBlock{
    width: 295px;
    height: 416px;
    background: linear-gradient(90deg, rgba(41, 22, 111, 0.2) 0%, rgba(41, 22, 111, 0) 21.12%), linear-gradient(270deg, rgba(41, 22, 111, 0.2) 0%, rgba(41, 22, 111, 0) 17.62%), rgba(0, 146, 221, 0.2);
    margin-right: 37px;
    flex-shrink: 0;
    cursor: pointer;
}

.aboutScrollBlockImage{
    width: 295px;
    height: 416px;
    object-fit: cover;
    flex-shrink: 1;
    
}

.forScaleAboutScrollBlockImage{
    width: 295px;
    height: 416px;
    object-fit: contain;
    flex-shrink: 1;
    transition: 0.3s;
    transition-property:all;
    
}

.bigImage{
    background: rgb(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
}