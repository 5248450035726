.newsDisplayBlock {
    background: #C5E2F1;
    margin: 20px 3% 20px 0px;
    box-shadow: 4px 4px 10px rgba(89, 116, 130, 0.25);
    border-radius: 25px 5px 25px 25px;
    width: 29.3%;
    aspect-ratio: 1/0.894;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: relative;
    transition: box-shadow 0.3s;
}

.newsDisplayBlock:hover {
    box-shadow: 6px 6px 10px rgba(89, 116, 130, 0.35);
}

.newsImage {
    width: 87.4%;
    aspect-ratio: 1/0.58;
    border-radius: 35px 5px 35px 35px;
    margin: 5.714%;
    margin-bottom: 7.14%;
    object-fit: cover;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.newsText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #201644;
    order: 1;
    flex-grow: 0;
    margin: 0px 20px 25px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.newsDisplayBlock:nth-child(10n+4) {
    border-radius: 25px 3px 25px 25px;
    width: calc(94%);
    aspect-ratio: 1/0.16126;
    display: flex;
    flex-direction: row;
}

.newsDisplayBlock:nth-child(10n+4) .newsImage {
    border-radius: 35px 5px 35px 35px;
    margin-top: calc(4.391px + 0.76vw);
    margin-left: 14.85%;
    height: calc(117.2174px + 0.978vw);
    width: calc(244.623px + 2.0764vw);
    aspect-ratio: 1/0.471;
    transition: 0.5s;
}

.newsDisplayBlock:nth-child(10n+4) .newsText {
    font-size: 20px;
    line-height: 23px;
    margin-top: 2%;
    display: inline;
    height: 100%;
}

.newsHr {
    display: none;
}

.hoverBox {
    display: flex;
    flex-direction: row;
}

.newsHoverImage {
    width: 0px;
    margin-left: 0%;
    opacity: 0;
    transition: 0.5s;
}

.newsDisplayBlock:nth-child(10n+4) .newsHoverImage {
    margin-left: 20%;
    margin-top: calc(2.391px + 0.76vw);
    width: 0px;
    height: calc(117.2174px + 0.978vw);
}

.newsDisplayBlock:hover .newsHoverImage {
    opacity: 1;
    margin-left: 5.714%;
    width: 40px;
}

.newsDisplayBlock:nth-child(10n+4):hover .newsHoverImage {
    width: 40px;
    margin-left: 25%;
}

.newsDisplayBlock:nth-child(10n+4):hover .newsImage {
    width: calc(256.232px + 2.175vw);
}

@media(min-width:1920px) {
    .newsDisplayBlock:nth-child(10n+4) .newsImage {
        margin: 20px;
        margin-left: 40px;
        height: 139px;
        width: 295px;
    }

    .newsDisplayBlock:nth-child(10n+4) .newsHoverImage {
        margin-left: 0%;
        margin-top: 17px;
        width: 0px;
        height: 139px;
    }

    .newsDisplayBlock:nth-child(10n+4):hover .newsImage {
        width: 309px;
    }

    .newsDisplayBlock:nth-child(10n+4):hover .newsHoverImage {
        width: 40px;
        margin-left: 0%;
    }
}

@media(max-width: 1000px) {
    .newsDisplayBlock {
        width: 29%;
        margin: 15px 12.5px 15px 12.5px;
    }

    .newsImage {
        aspect-ratio: 1/0.68;
    }

    .newsDisplayBlock:nth-child(10n+4) {
        width: calc(93%);
        aspect-ratio: 1/0.1947;
    }

    .newsDisplayBlock:nth-child(10n+4) .newsImage {
        margin-top: calc(6.465vw - 39.655px);
        height: calc(65.34px + 6.465vw);
        width: calc(144.1379px + 15vw);
    }

    .newsDisplayBlock:nth-child(10n+4) .newsHoverImage {
        margin-top: calc(6.465vw - 39.655px);
        height: calc(65.34px + 6.465vw);
        margin-left: 10%
    }

    .newsDisplayBlock:nth-child(10n+4):hover .newsHoverImage {
        width: 40px;
        margin-left: 10%;
    }

    .newsDisplayBlock:nth-child(10n+4):hover .newsImage {
        width: calc(149.68px + 15.5769vw);
    }
}

@media(max-width: 768px) {
    .newsHoverImage {
        display: none;
    }

    .newsDisplayBlock:nth-child(10n+4):hover .newsImage {
        width: calc(66.034px + 19.396vw);
    }

    .newsDisplayBlock {
        width: 29.5%;
        margin: 7.5px 5px 7.5px 5px;
    }

    .newsImage {
        border-radius: 25px 5px 25px 25px;
    }

    .newsText {
        font-size: 15px;
        line-height: 130%;
    }

    .newsDisplayBlock:nth-child(10n+4) {
        width: 92%;
        aspect-ratio: 1/0.228;
    }

    .newsDisplayBlock:nth-child(10n+4) .newsImage {
        margin-top: calc(6.465vw - 30.655px);
        height: calc(65.34px + 6.465vw);
        width: calc(66.034px + 19.396vw);
        transition: none;
    }

    .newsDisplayBlock:nth-child(10n+4) .newsText {
        font-size: 18px;
        line-height: 21px;
        margin-left: 10%;
        margin-top: 4%
    }
}

@media(max-width: 600px) {
    .newsDisplayBlock {
        width: 46%;
        margin: 2%;
        margin-bottom: 3.62%;
        aspect-ratio: 1/0.929;
    }

    .newsText {
        font-size: 13px;
        line-height: 130%;
    }

    .newsImage {
        aspect-ratio: 1/0.6857;
    }

    .newsDisplayBlock:nth-child(10n+4) {
        width: 46%;
        flex-direction: column;
    }

    .newsDisplayBlock:nth-child(10n+4) .newsImage {
        width: 87.4%;
        height: auto;
        margin: 5.714%;
        margin-bottom: 7.14%;
        flex: none;
        order: 0;
        flex-grow: 0;
        aspect-ratio: 1/0.6857;
    }

    .newsDisplayBlock:nth-child(10n+4):hover .newsImage {
        width: 87.4%;
        height: auto;
    }

    .newsDisplayBlock:nth-child(10n+4) .newsText {
        margin-top: 0%;
        font-size: 13px;
        line-height: 130%;
        display: -webkit-box;
    }

    .newsDisplayBlock:nth-child(9n+5) {
        width: 100%;
        aspect-ratio: 1/0.28;
        flex-direction: row;
    }

    .newsDisplayBlock:nth-child(9n+5) .newsImage {
        width: 34.5vw;
        aspect-ratio: 1/0.636;
        margin: 6%;
        margin-left: 12%;
    }

    .newsDisplayBlock:nth-child(9n+5) .newsText {
        margin-left: 12%;
        margin-top: 3%;
        font-size: 15px;
        line-height: 18px;
        display: inline;
    }
}

@media(max-width: 440px) {
    .newsDisplayBlock {
        width: 100%;
        aspect-ratio: 1/0.418;
        flex-direction: row;
    }

    .newsText {
        margin-left: 10%;
        margin-top: 3%;
        font-size: 13px;
        line-height: 15px;
        display: inline;
    }

    .newsImage {
        margin: 7%;
        border-radius: 25px 5px 25px 25px;
        width: 39vw;
        aspect-ratio: 1/0.67857;
    }

    .newsDisplayBlock:nth-child(10n+4) {
        width: 100%;
        aspect-ratio: 1/0.418;
        flex-direction: row;
    }

    .newsDisplayBlock:nth-child(10n+4) .newsText {
        margin-left: 10%;
        margin-top: 3%;
        font-size: 13px;
        line-height: 15px;
        display: inline;
    }

    .newsDisplayBlock:nth-child(10n+4) .newsImage {
        margin: 7%;
        border-radius: 25px 5px 25px 25px;
        width: 39vw;
        aspect-ratio: 1/0.67857;
    }

    .newsDisplayBlock:nth-child(10n+4):hover .newsImage {
        width: 39vw;
        aspect-ratio: 1/0.67857;
    }

    .newsDisplayBlock:nth-child(9n+5) {
        width: 100%;
        aspect-ratio: 1/0.418;
        flex-direction: row;
    }

    .newsDisplayBlock:nth-child(9n+5) .newsText {
        margin-left: 10%;
        margin-top: 3%;
        font-size: 13px;
        line-height: 15px;
        display: inline;
    }

    .newsDisplayBlock:nth-child(9n+5) .newsImage {
        margin: 7%;
        border-radius: 25px 5px 25px 25px;
        width: 39vw;
        aspect-ratio: 1/0.67857;
    }

    .newsImageHolder {
        display: flex;
        flex-direction: column;
    }

    .newsHr {
        margin-left: 10%;
        width: 37vw;
        display: flex;
    }
}

.closeButtonWrapper {
    position: absolute;
    top: 0;
    right: 0px;
    color: white;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    top: -7px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 1000px;
    cursor: pointer;
}

.closeButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}

.editButtonWrapper {
    position: absolute;
    color: white;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    top: 15px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 1000px;
    cursor: pointer;
}

.editButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}

.link {
    text-decoration: none;
    color: #201644
}

.link:active {
    text-decoration: none;
    color: #201644
        /* color: #201644; */
}