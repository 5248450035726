.renderer {
    white-space: pre-wrap;
    font-size: 16px;
    font-family: 'Verdana';
    font-weight: 500;
    line-height: 130%;
}
.paragraphWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}
.paragraph{
    width: calc(20.76vw + 711.39px);
}
.separatorWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}
.imageRightText{
    margin-left:calc(38.8vw - 348.043px);
    margin-top: 57px;
    order: 1;
}
.imageLeftText{
    margin-right:calc(38.8vw - 348.043px);
    margin-top: 57px;
    order: 2;
}
.imageCenter{
    background-color: white;
    width: calc((20.76vw + 711.39px) * var(--scale));
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}
.imageLeft{
    background-color: white;
    width: calc((49.02vw - 111.217px) * var(--scale));
    margin-bottom: 20px;
    border-radius: 0px 25px 25px 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    margin-right: 50px;
}
.imageRight{
    background-color: white;
    width: calc((49.02vw - 111.217px) * var(--scale));
    border-radius: 25px 0px 0px 25px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    margin-left: 50px;
}
.imageWrapperCenter{
    display: flex;
    flex-direction: column;
}
.imageWrapperLeft{
    display: flex;
    flex-direction: row;
}
.imageWrapperRight{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.imageOrderControlRight{
    order: 2;
}
.imageOrderControlLeft{
    order: 1;
}
.imageTextOrderControlRight{
    order: 1;
}
.imageTextOrderControlLeft{
    order: 2;
}
.conclusionHr{
    width: 100%;
    background: #0092DD;
    height: 1px;
    box-shadow: 0px 4px 8px 0px rgba(89, 116, 130, 0.25);
}
.arrowConclusion{
    width: calc(203.217px + 53.478vw);
    min-height: calc(293.913px + 7.6vw);
    flex-shrink: 0;
    border-radius: 0px 25px 25px 0px;
    background: var(--rectang, rgba(216, 216, 216, 0.40));
    box-shadow: 4px 4px 10px 0px rgba(89, 116, 130, 0.25);
    margin-bottom: 2%;
}
.arrowConclusionText{
    display: flex;
    margin-left: calc(42.816vw - 227.073px);
    margin-right: calc(20.337px + 0.5vw);
    margin-top: calc(33.4px + 0.864vw);
    margin-bottom: calc(29.3913px + 0.76vw);
}
.arrowConclusionBlob{
    display: flex;
    position: absolute;
    width: calc(446.058px + 11vw);
    height: calc(220.638px + 5.71vw);
    flex-shrink: 0;
    z-index: 1;
    margin-top: calc(33.408px + 0.864vw);
    margin-left: calc(40.63vw - 315.17px)
}
.arrowConclusionArrow{
    width: calc(57.598px + 1.43vw);
    height: calc(51.43478px + 1.33vw);
    flex-shrink: 0;
    position: absolute;
    margin-left: calc(39.626vw - 355.82px);
    margin-top: calc(15.36px + 0.3978vw);
    display: flex;
    z-index: 3;
}

.galleryMainBox{
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    width: calc(713.478px + 20.65vw);
    height: calc(11.413vw + 380.869px);
    scroll-behavior: smooth;
}
.galleryMainBox::-webkit-scrollbar {
    height: 0px;
}
.galleryBottomBox{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: calc(713.478px + 20.65vw);
}
.galleryBottomBox::-webkit-scrollbar{
    height: 15px;
}
.galleryBottomBox::-webkit-scrollbar-track {
    background-color: darkgrey;
    border-radius: 10px;
}
.galleryBottomBox::-webkit-scrollbar-thumb {
    background-color: #0092DD;
    border-radius: 10px;
}
.galleryTopItemImage{
    height: 100%;
    width: calc(713.478px + 20.65vw);
    object-fit: cover;
}
.galleryBottomItemImage{
    object-fit: cover;
    width: 255px;
    height: 140px;
}
.galleryBottomItem{
    display:'flex';
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 28px;
    cursor: pointer;
}
.galleryBottomItem:last-child{
    margin-right: 1px;
}

.galleryButtonBox{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: calc(713.478px + 20.65vw);
    margin-bottom: 80px;
    height: 0px;
}
.leftGalleryButton{
    cursor: pointer;
    transition: 0.1s;
    margin-left: 20px;
    user-select: none;
}
.rightGalleryButton{
    cursor: pointer;
    transition: 0.1s;
    margin-right: 20px;
    user-select: none;
}
.dotWrapper{
    position: absolute;
    width: calc(713.478px + 20.65vw);
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    user-select: none;
}
.galleryFullScreenImage{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
@media(min-width:1920px){
    .galleryMainBox{
        width: 1110px;
        height: 600px;
    }
    .galleryBottomBox{
        width: 1110px;
    }
    .galleryTopItemImage{
        width: 1110px;
    }
    .galleryButtonBox{
        width: 1110px;
    }
    .dotWrapper{
        width: 1110px;
    }
    .galleryBottomItemImage{
        width: 255px;
        height: 140px;
    }

    .paragraph{
        width: 1110px;
    } 
    .imageRightText{
        margin-left:397px
    }
    .imageLeftText{
        margin-right:397px
    }
    .imageCenter{
        width: calc(1110px * var(--scale));
    }
    .imageLeft{
        width: calc(830px * var(--scale));
    }
    .imageRight{
        width: calc(830px * var(--scale));
    }
    .arrowConclusion{
        width: 1230px;
        min-height: 440px;
    }
    .arrowConclusionBlob{
        width: 658.262px;
        height: 330.304px;
        margin-top: 50px;
        margin-left: 465px;
    }
    .arrowConclusionArrow{
        width: 85px;
        height: 77px;
        margin-left: 405px;
        margin-top: 23px;
    }
    .arrowConclusionText{
        margin-left: 595px;
        margin-right: 30px;
        margin-top: 50px;
        margin-bottom: 44px;
    }
}

@media(max-width:1000px){
    .galleryMainBox{
        width: calc(65.517vw + 264.8276px);
        height: calc(35.23vw + 142.7155px);
    }
    .galleryBottomBox{
        width: calc(65.517vw + 264.8276px);
    }
    .galleryTopItemImage{
        width: calc(65.517vw + 264.8276px);
    }
    .galleryButtonBox{
        width: calc(65.517vw + 264.8276px);
    }
    .dotWrapper{
        width: calc(65.517vw + 264.8276px);
    }

    .paragraph{
        width: calc(124.138vw - 322.38px);
    } 
    .imageRightText{
        margin-left:calc(156.379px - 11.637vw);
    }
    .imageLeftText{
        margin-right:calc(156.379px - 11.637vw);
    }    
    .imageCenter{
        width: calc((124.138vw - 322.38px) * var(--scale));
    }
    .imageLeft{
        width: calc((317.349px + 6.165vw) * var(--scale));
    }
    .imageRight{
        width: calc((317.349px + 6.165vw) * var(--scale));
    }
    .arrowConclusion{
        width: calc(232.93 + 48.7vw);
        min-height: calc(132.93px + 23.7vw);
    }
    .arrowConclusionBlob{
        width: calc(199.04px + 35.75vw);
        height: calc(99.79px + 17.7966vw);
        margin-left: calc(14.914vw - 57.968px);
        margin-top: calc(15.11px + 2.694vw);
    }
    .arrowConclusionArrow{
        width: calc(18.84px + 5.3vw);
        height: calc(24.181px + 4.057vw);
        margin-left:calc(11.655vw - 76.11px);
        margin-top: calc(6.969px + 1.237vw);
    }
    .arrowConclusionText{
        margin-left: calc(29.78vw - 96.71px);
        margin-right: calc(88.43px - 6.3vw);
        margin-top: calc(11.662px + 3.04vw);
        margin-bottom: calc(1.293vw + 24.069px);
    }
}

@media(max-width:768px){
    .galleryMainBox{
        width: 100vw;
        height: calc(53.81vw + 0.006px);
    }
    .galleryBottomBox{
        width: 100vw;
    }
    .galleryTopItemImage{
        width: 100vw;
    }
    .galleryButtonBox{
        width: 100vw;
    }
    .dotWrapper{
        width: 100vw;
    }
    .renderer{
        font-size: 14px;
    }
    .paragraph{
        width: calc(64.5833vw + 135px);
    } 
    .imageRightText{
        margin-left:0px;
        margin-top: 0px;
        width: calc(64.5833vw + 135px);
    }
    .imageLeftText{
        margin-right:0px;
        margin-top: 0px;
        width: calc(64.5833vw + 135px);
    }    
    .imageCenter{
        width: calc((64.5833vw + 135px) * var(--scale));
    }
    .imageWrapperLeft{
        flex-direction: column;
    }
    .imageWrapperRight{
        justify-content: nones;
        flex-direction: column;
    }
    .imageOrderControlRight{
        order: 1;
    }
    .imageTextOrderControlRight{
        order: 2;
    }
    .imageLeft{
        width: calc(364.697px * var(--scale));
    }
    .imageRight{
        width: calc(364.697px * var(--scale));
    }
    .arrowConclusion{
        width: calc(223px + 50vw);
        min-height: calc(541.67px - 29.514vw);
    }
    .arrowConclusionBlob{
        margin-top: 35.8px;
    }
    .arrowConclusionArrow{
        width: calc(36.23px + 3.038vw);
        height: calc(34.255px + 2.745vw);
        margin-left:calc(25.67px - 1.597vw);
        margin-top: calc(20.55px - 0.53125vw);
    }
    .arrowConclusionText{
        margin-left: calc(81.33px + 6.597vw);
        margin-right: calc(21.33px + 2.43vw);
        margin-top: calc(27px + 1.04167vw);
        margin-bottom: calc(28.67px + 0.694vw);
    }
}
@media(max-width:700px){
    .arrowConclusionBlob{
        display: none
    }
}

@media(max-width:480px){
    .galleryMainBox{
        height: 258.295px;
    }

    .paragraph{
        width: calc(91.66vw + 5px);
    } 
    .imageRightText{
        width: calc(91.66vw + 5px);
    }
    .imageLeftText{
        width: calc(91.66vw + 5px);
    }    
    .imageCenter{
        width: calc((91.66vw + 5px) * var(--scale));
    }
    .imageLeft{
        width: calc((261.577px + 21.48vw) * var(--scale));
    }
    .imageRight{
        width: calc((261.577px + 21.48vw) * var(--scale));
    }
    .arrowConclusion{
        width: 96.458vw;
        min-height: calc(480px - 16.67vw);
    }
    .arrowConclusionArrow{
        width: calc(15.549px + 7.35vw);
        height: calc(17.704px + 6.193vw);
        margin-left:calc(4.167vw - 2px);
        margin-top: calc(6px + 2.5vw);
    }
    .arrowConclusionText{
        margin-left: calc(83.33vw - 287px);
        margin-right: calc(17.5vw - 51px);
        margin-top: calc(184px - 31.66vw);
        margin-bottom: 32px;
    }
}

@media(max-width:360px){
    .paragraph{
        width: 335px;
    } 
    .imageRightText{
        width: 335px;
    }
    .imageLeftText{
        width: 335px;
    } 
    .imageCenter{
        width: calc(335px * var(--scale));
    }
    .imageLeft{
        width: calc(338.917px * var(--scale));
    }
    .imageRight{
        width: calc(338.917px * var(--scale));
    }
    .arrowConclusion{
        width: 100%;
        min-height: 420px;
        border-radius: 0;
    }
    .arrowConclusionArrow{
        width: 42px;
        height: 40px;
        margin-left: 13px;
        margin-top: 15px;
    }
    .arrowConclusionText{
        margin-left: 13px;
        margin-right: 12px;
        margin-top: 70px;
        margin-bottom: 32px;
    }
}