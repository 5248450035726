.greyHeader {
    height: 360px;
    background: rgb(234, 234, 234);
    width: 100%;
}

.topTextBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 3%;
    max-width: 1920px;
    width: 100%;
    color: #201644;

}

.topLeftText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #201644;
    margin-top: 3.7%;
    margin-left: calc(39.13vw - 331.3px);
    width: 450px;
    z-index: 2;

}

.topRightText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-top: 3.7%;
    width: 600px;
    color: #201644;
    z-index: 2;
    margin-right: 1%;
}

.navigationWrapper {
    width: 350px;
    height: 547px;
    background: #91C2EF;
    border-radius: 20px;
    position: fixed;
    top: 300px;
    margin-left: 20px;
    z-index: 100;
}

.navigationHeader {
    height: 150px;
    border-radius: 20px;
    background-color: #2E81CE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headerText {
    font-size: 32px;
    color: white;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
}

.subHeaderText {
    margin-top: 10px;
    max-width: 243px;
    color: white;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
}

.subHeaderWrapper {
    display: flex;
    align-items: center;
}

.justifier {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: relative;
}

.newsHeaderElementWrapper {
    padding: 40px;
    height: 315px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.newsHeaderElement:hover {
    color: rgb(65, 65, 65);
}

.newsHeaderElementWrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.newsHeaderElement {
    font-size: 23px;
    margin-bottom: 34px;
    cursor: pointer;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
}

.viewWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
}

.closeButtonWrapper {
    position: absolute;
    top: 0;
    right: 0px;
    color: white;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    top: -7px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 1000px;
    cursor: pointer;
}

.closeButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}

.editButtonWrapper {
    position: absolute;
    color: black;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 1000px;
    cursor: pointer;
}

.editButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}

@media(min-width:1920px) {
    .topLeftText {
        margin-left: 420px;
    }
}

@media(max-width:1900px) {
    .navigationWrapper {
        width: 320px;
    }
}

@media(max-width:1850px) {
    .navigationWrapper {
        width: 280px;
    }
}

@media(max-width:1770px) {
    .newsIcon {
        display: none;
    }

    .headerText {
        font-size: 28px;
    }

    .subHeaderText {
        font-size: 14px;
    }

    .newsHeaderElement {
        font-size: 18px;
    }
}

@media(max-width:1600px) {
    .navigationWrapper {
        width: 200px;
        
    }
    .headerText {
        font-size: 20px;
        margin-left: 10px;
    }

    .subHeaderText {
        font-size: 12px;
        margin-left: 10px;
    }

    .newsHeaderElement {
        font-size: 14px;
    }
}

@media(max-width:1450px) {
    .navigationWrapper{
        display: none;
    }
}


@media(max-width:1300px) {
    .topTextBox {
        flex-direction: column;
        align-items: center;
    }

    .topLeftText {
        margin-top: 10px;
    }

    .topRightText {
        margin-top: 30px;
    }

}




@media(max-width: 1000px) {

    .topLeftText {
        font-size: 30px;
        line-height: 35px;
    }

    .topRightText {
        font-size: 16px;
        line-height: 130%;
    }
}


@media(max-width: 700px) {
    .topTextBox {
        justify-content: center;
        align-items: center;
    }

    .topLeftText {
        font-size: 18px;
        line-height: 21px;
        color: #201644;
        max-width: 300px;
        text-align: center;
    }

    .topRightText {
        font-size: 12px;
        line-height: 130%;
        color: #201644;
        max-width: 300px;

    }
}


@media(max-width: 440px) {

    .topLeftText {
        color: #201644;
    }

    .topRightText {
        color: #201644;
    }
}


.shadow {
    background: rgb(0, 0, 0, 0.6);
    /* opacity: 0.6; */
    position: fixed;
    z-index: 1010;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.labDescription {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

}

.inputDescriptionStyle {
    Border: none;
    outline: none;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

}


.wrapProd2 {
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(89, 116, 130, 0.15);
    width: 260px;
    border-radius: 15px 4px 15px 15px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    margin-top: 10px;
}

.wrapProd2:last-child {
    margin-right: 0px;
}

.createButtonWrapper {
    background: #0092DD;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
    border-radius: 100px;
    cursor: pointer;
    color: #FFFFFF;
    margin-bottom: 40px;
}