.justifier{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.order{
    width: 100%;
    max-width: 1920px;
    overflow: hidden;
}
.orderTint{
    width: 100%;
    position: absolute;
    height: calc(73.913px + 2.6vw);
    background: rgba(216, 216, 216, 0.4);
    z-index: -1;
}
.orderSeparatorWrapper{
    display: flex;
    justify-content: center;
}
.orderSeparator{
    border-bottom: 1px solid #0092DD;
    width: calc(20.76vw + 711.39px);
    height: 0px;
    margin-top: calc(72.913px + 2.6vw);
}
.orderHeaderWrapper{
    display: flex;
    justify-content: center;
}
.orderHeader{
    position: absolute;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #201644;
    z-index: 2;
    margin-top: 74px;
    width: calc(20.76vw + 711.39px);
}
.orderText{
    width: calc(20.76vw + 711.39px);
    text-align: justify;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-top: 40px;
    z-index: 10;
    display: flex;
    color: #201644;
}
.orderCross{
    display: flex;
    margin-left: calc(34.78vw - 527.826px);
    margin-top: -102px;
    z-index: 0;
}
.orderButtons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-top: -92px;
    margin-bottom: 196px;
    width: calc(20.76vw + 711.39px);
}


@media(min-width:1920px) {
    .orderText {
        width: 1110px;
    }
    .orderSeparator {
        margin-top: 123px;
        width: 1110px;
    }
    .orderHeader{
        width: 1110px;
    }
    .orderButtons{
        width: 1110px;
    }
    .orderCross{
        margin-left: 140px;
    }
    .orderTint{
        max-width: 1920px;
        height: 124px;
    }
}

@media(max-width:1000px) {
    .orderText {
        width: calc(124.138vw - 322.38px);
    }
    .orderSeparator {
        width: calc(124.138vw - 322.38px);
        margin-top: 99px;
    }
    .orderHeader{
        margin-top: 50px;
        width: calc(124.138vw - 322.38px);
    }
    .orderButtons{
        width: calc(124.138vw - 322.38px);
    }
    .orderCross{
        margin-left: -180px;
    }
    .orderTint{
        height: 100px;
    }
}

@media(max-width:768px) {
    .orderText {
        width: calc(64.5833vw + 135px);
        font-size: 15px;
    }
    .orderSeparator {
        width: calc(64.5833vw + 135px);
    }
    .orderHeader{
        margin-top: 34px;
        font-size: 18px;
        width: calc(64.5833vw + 135px);
    }
    .orderCross{
        width: calc(197.916vw - 125px);
        margin-left: calc(16.133px - 25.538vw);
    }
    .orderButtons{
        width: calc(64.5833vw + 135px);
    }
}

@media(max-width:700px){
    .orderButtons{
        flex-direction: column;
        margin-left: 0px;
        width: 100%;
    }
}

@media(max-width:480px) {
    .orderText {
        font-size: 13px;
        width: calc(91.66vw + 5px);
    }
    .orderSeparator {
        width: calc(91.66vw + 5px);
        margin-top: calc(59px + 8.333vw);
    }
    .orderHeader{
        margin-top: 35px;
        font-size: 17px;
        width: calc(91.66vw + 5px);
    }
    .orderTint{
        height: calc(60px + 8.333vw);
    }
}

@media(max-width:400px) {
    .orderCross{
        display: none
    }
    .orderButtons{
        margin-top: 40px;
    }
    .orderHeader{
        margin-top: 18px;
    }
}

@media(max-width:360px) {
    .orderText {
        width: 335px;
    }
    .orderSeparator {
        width: 335px;
        margin-top: 89px;
    }
    .orderHeader{
        font-size: 16px;
        width: 335px;
    }
    .orderTint{
        height: 90px;
    }
}