.kpWrapper {
    cursor: pointer;
    z-index: 400;
    position: fixed;
    left: max(3.5vmax, 70px);
    bottom: max(2vmax, 40px);
    border-radius: max(1vmax, 20px) max(0.25vmax, 5px) max(1vmax, 20px) max(1vmax, 20px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), #0092DD;
    display: inline-flex;
    padding: max(16px, 0.8vmax) max(20px, 1vmax);
    align-items: center;
    gap: 15px;
    user-select: none;
    width: max(3vmax, 50px);
    height: max(2vmax, 30px);
}