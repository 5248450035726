.menuWrapper {
    background: #C5E2F1;
    border-radius: 0px 5px 15px 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.menuElement {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* text */

    color: #201644;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 25px;
    cursor: pointer;
    padding-left: 5px;
}

.menuElement:hover {
    color: #808080;
}

@media (max-width:850px) {
    .menuElement {
        width: 100px;
    }
}

@media (max-width:600px) {
    .menuElement {
        width: 300px;
    }

    .menuElement:hover {
        color: #808080;
    }
}

.subDirectoryElement {
    border: 1px solid #0092DD;
    border-radius: 15px 5px 15px 15px;


    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 17px */

    display: flex;
    align-items: center;

    /* accent */

    color: #0092DD;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
}

.subDirectoryElement:hover {
    background: linear-gradient(175.2deg, #C5E2F1 3.88%, rgba(197, 226, 241, 0) 50.02%);

    align-items: stretch;
}

.subDirectoryElement:last-child {
    margin-right: 0;
    align-items: stretch;
}

.subDirectoriesWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    align-items: stretch;


}

.wrapProd {
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(89, 116, 130, 0.15);
    width: 260px;
    border-radius: 15px 4px 15px 15px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    transition: box-shadow 0.3s;
}

.wrapProd:hover{
    box-shadow: 4px 4px 8px rgba(89, 116, 130, 0.3);
}

.wrapProd:last-child {
    margin-right: 0px;
}

.wrapProd2 {
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(89, 116, 130, 0.15);
    width: 260px;
    border-radius: 15px 4px 15px 15px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    margin-top: 10px;
}

.wrapProd2:last-child {
    margin-right: 0px;
}

.addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 100px;
}


.inputStyle {
    Border: none;
    outline: none;
    margin-top: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 17px */
    display: flex;
    align-items: center;
    color: rgba(32, 22, 68, 0.8);

}

.inputDescriptionStyle {
    Border: none;
    outline: none;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

    color: #0092DD;
}

.labName {

    margin-top: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.1282051282051282vw + 10.538461538461538px);
    line-height: 130%;
    /* or 17px */
    display: flex;
    align-items: center;
    color: rgba(32, 22, 68, 0.8);
}

.labDescription {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.1282051282051282vw + 13.538461538461538px);
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

    color: #0092DD;
}

.footerWrapper {
    margin-top: 100px;
    background: rgba(216, 216, 216, 0.4);
    display: flex;
    height: 170px;
    align-items: center;
    width: 1920px;
    justify-content: center;

}

.warningText {
    margin-left: 20px;
    margin-right: 40px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    color: #201644;
    display: flex;
    align-items: center;
    flex: 1;
}

.backgraundGradient {
    height: 250px;
    width: 100%;

    /* background: red; */
    position: absolute;
    top: 0;
    left: 0;
}

.smartMenu {
    background: #C5E2F1;
    box-shadow: 0px 4px 10px rgba(89, 116, 130, 0.25);
    height: 64px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.smartMenuV2 {
    background: #C5E2F1;
    box-shadow: 0px 4px 10px rgba(89, 116, 130, 0.25);
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
}

.margen {
    height: 10px;
    background: rgba(216, 216, 216, 0.4);
}

.shadow {
    background: rgb(0, 0, 0, 0.6);
    /* opacity: 0.6; */
    position: fixed;
    z-index: 1010;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createButtonWrapper {
    background: #0092DD;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
    border-radius: 100px;
    cursor: pointer;
}


.closeButtonWrapper {
    position: absolute;
    color: white;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    top: -7px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 1000px;
    cursor: pointer;
}

.closeButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}

.plusWrapper {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 3px;
    margin-bottom: 3px;
    min-height: 70px;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: #0092DD;
    font-size: 50px;
}

.editButtonWrapper {
    position: absolute;
    color: white;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    top: 15px;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 1000px;
    cursor: pointer;
}

.editButtonWrapper:hover {
    background: rgb(0, 0, 0, 0.5);
}

.modalWrapper {
    width: 500px;
    height: 200px;
    background: white;
    border-radius: 16px;
}

.modalText {
    margin-top: 10px;
    text-align: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    color: #201644;
}

.alertButtonWrapperDelete {
    color: #0092DD;
    border: 1px solid #0092DD;
    border-radius: 16px;
    padding: 10px;
    width: 150px;
    text-align: center;
    margin-left: 50px;
    margin-top: 30px;
    cursor: pointer;
}

.alertButtonWrapperCancel {
    cursor: pointer;
    margin-top: 30px;
    color: #0092DD;
    border: 1px solid #0092DD;
    border-radius: 16px;
    padding: 10px;
    width: 150px;
    text-align: center;
    margin-right: 50px;
}

.alertButtonWrapperDelete:hover {
    background: rgb(0, 146, 221, 0.05);
}

.alertButtonWrapperCancel:hover {
    background: rgb(0, 146, 221, 0.05);
}

.breadcrumbsStyle {
    flex: 1110px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0092DD;
    font-size: 13px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbsElement:hover {
    text-decoration: underline;
    cursor: pointer;
}

.link {
    text-decoration: none;
}

.link:active {
    text-decoration: none;
    /* color: #201644; */
}

.kpWrapper {
    cursor: pointer;
    z-index: 400;
    position: fixed;
    right: 40px;
    bottom: 30px;
    border-radius: 20px 5px 20px 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), #0092DD;
    display: inline-flex;
    padding: 16px 20px;
    align-items: center;
    gap: 15px;
    user-select: none;

}