.wrapProd2 {
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(89, 116, 130, 0.15);
    width: 260px;
    border-radius: 15px 4px 15px 15px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    margin-top: 10px;
}

.wrapProd2:last-child {
    margin-right: 0px;
}
.labName {

    margin-top: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 17px */
    display: flex;
    align-items: center;
    color: rgba(32, 22, 68, 0.8);
}
.labDescription {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

    color: #0092DD;
}
.inputStyle {
    Border: none;
    outline: none;
    margin-top: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 17px */
    display: flex;
    align-items: center;
    color: rgba(32, 22, 68, 0.8);

}
.inputDescriptionStyle {
    Border: none;
    outline: none;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

    color: #0092DD;
}
.createButtonWrapper {
    background: #0092DD;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
    border-radius: 100px;
    cursor: pointer;
    color: #FFFFFF;
    margin-bottom: 40px;
}
.buttonWrapper{
    color: #0092DD;
    border: 1px solid;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    margin: 10px;
    cursor: pointer;
}
.headerProduct {
    background: rgba(216, 216, 216, 0.4);
    display: flex;
}
