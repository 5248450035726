.shadowWrapper {
    background: rgb(0, 0, 0, 0.6);
    /* opacity: 0.6; */
    position: fixed;
    z-index: 1012;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CommercialOfferWrapper {
    min-width: 768px;
    min-height: 320px;
    width: 50vmax;
    height: 21vmax;
    border-radius: max(0.5vmax, 10px);
    background: #F6F6F6;
    box-shadow: 0.1vmax 0.25vmax 0.5vmax 0px rgba(89, 116, 130, 0.25);
}

.textHeader {
    color: var(--text, #201644);
    font-family: 'Raleway';
    font-size: max(1.4vmax, 21px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2vmax;
    margin-left: 2.5vmax;
}

.text {
    color: #201644;
    /* text1 */
    font-family: 'Raleway';
    font-size: max(0.8vmax, 12px);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-top: 1vmax;
    margin-left: 2.5vmax;
}

.BlueButtonWrapper {
    margin-left: 2.5vmax;
    margin-top: max(30px, 2.8vmax);
    width: 13.5vmax;
    min-width: 270px;
    height: 2vmax;
    min-height: 40px;
}

.checkBox {
    cursor: pointer;
    margin-left: 2.5vmax;
    display: flex;
    align-items: center;
    width: 0.85vmax;
    height: 0.85vmax;
    min-width: 17px;
    min-height: 17px;
}

.checkBoxWrapper {
    display: flex;
    align-items: center;
}

.checkBoxText {
    display: flex;
    align-items: end;
    color: #383842;
    font-family: 'Raleway';
    font-size: max(0.8vmax, 10px);
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-left: 0.4vmax;
}

.buttonWrapper {
    border-radius: max(0.5vmax, 10px) 0.05vmax max(0.5vmax, 10px) max(0.5vmax, 10px);
    height: 2vmax;
    min-height: 40px;
    min-width: 240px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
}

.buttonText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: max(0.8vmax, 12px);
    line-height: 0.95vmax;
    color: #FFFFFF;
}

.checkBoxesWrapper {
    margin-top: 1.25vmax;
}

.closeLineWrapper {

    margin-top: 1vmax;
    display: flex;
    justify-content: end;
    margin-right: 1vmax;
}

.closeWrapper {
    cursor: pointer;
    min-width: 15px;
    min-height: 15px;
    width: 0.75vmax;
    height: 0.75vmax;
}

.inputName {
    border-radius: max(0.25vmax, 5px) max(0.25vmax, 5px) 0px 0px;
    border-bottom: 1px solid #0092DD;
    background: var(--rectang, rgba(216, 216, 216, 0.40));
    outline: none;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    width: 8.75vmax;
    height: 1vmax;
    padding: 0.25vmax 0px 0.25vmax 0.75vmax;
    min-width: 175px;
    min-height: 20px;
    margin-right: 10px;
    color: #201644;
    font-family: 'Raleway';
    font-size: max(16px, 0.8vmax);
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
}

.inputName::placeholder {
    color: #383842;
    font-family: 'Raleway';
    font-size: max(16px, 0.8vmax);
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
}

.inputMail {
    border-radius: max(0.25vmax, 5px) max(0.25vmax, 5px) 0px 0px;
    border-bottom: 0.05vmax solid #0092DD;
    background: var(--rectang, rgba(216, 216, 216, 0.40));
    outline: none;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    width: 10.5vmax;
    height: 1vmax;
    padding: 0.25vmax 0px 0.25vmax 0.75vmax;
    margin-right: 3vmax;
    min-width: 210px;
    min-height: 20px;
    color: #201644;
    font-family: 'Raleway';
    font-size: max(16px, 0.8vmax);
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
}

.inputMail::placeholder {
    color: #383842;
    font-family: 'Raleway';
    font-size: max(16px, 0.8vmax);
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
}

.phoneWrapper {
    margin-top: max(1.1vmax, 22px);
    margin-bottom: 0.4vmax;
    color: #383842;
    font-family: 'Raleway';
    font-size: max(17px, 0.85vmax);
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.productBoxWrapper {
    margin-top: max(2.1vmax, 40px);
    display: flex;
    justify-content: end;
}

.productBox {
    width: max(13vmax, 260px);
    /* background: green; */

    margin-right: 3vmax;
    position: relative;
}

.productEdit {
    cursor: pointer;
    position: relative;
    margin-top: 0.55vmax;
    border-radius: max(5px, 0.25vmax) max(5px, 0.25vmax) 0px 0px;
    border-bottom: 1px solid #0092DD;
    background: var(--rectang, rgba(216, 216, 216, 0.40));
    height: max(30px, 1.5vmax);
    display: flex;
    align-items: center;
    padding-left: max(15px, 0.75vmax);
    color: #383842;
    font-family: 'Raleway';
    font-size: max(16px, 0.8vmax);
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
}

.productHeader {
    color: #383842;
    font-family: 'Raleway';
    font-size: max(17px, 0.85vmax);
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.arrowvnizImage {
    width: max(18px, 0.9vmax);
    height: max(15px, 0.75vmax);
    position: relative;
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.5vmax;
    pointer-events: none;
    user-select: none;
}

.selectStyle {
    position: absolute;
    width: 100%;
    left: 0px;
    height: 10px;
    cursor: pointer;
    top: 0px;

}

.selectStyle2 {
    cursor: pointer;
    height: max(30px, 1.5vmax);
}

.CommercialOfferWrapperMini {
    width: 360px;
    border-radius: 10px;
    background: #F6F6F6;
    box-shadow: 0.1vmax 0.25vmax 0.5vmax 0px rgba(89, 116, 130, 0.25);
}

.textHeaderMini {
    color: var(--text, #201644);
    font-family: 'Raleway';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 17px;
    margin-left: 13px;
    width: 300px;
}

.inputNameMini {
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #0092DD;
    background: var(--rectang, rgba(216, 216, 216, 0.40));
    outline: none;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    width: 320px;
    height: 21px;
    padding: 5px 0 5px 15px;
    color: #201644;
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    margin-left: 15px;
}

.inputNameMini::placeholder {
    color: #383842;
    font-family: 'Raleway';
    font-size: max(16px, 0.8vmax);
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
}

.phoneWrapperMini {
    margin-left: 15px;
    margin-top: 24px;
    color: #383842;
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.productBoxWrapperMini {
    margin-top: 20px;
    display: flex;
    margin-left: 15px;
    height: 50px;
}

.productBoxMini {
    width: 335px;
    position: relative;
}

.productEditMini {

    height: 30px;
    cursor: pointer;
    position: relative;
    margin-top: 0.55vmax;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #0092DD;
    background: var(--rectang, rgba(216, 216, 216, 0.40));
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    color: #383842;
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
}

.selectStyleMini {
    position: absolute;
    width: 100%;
    left: 0px;
    height: 10px;
    cursor: pointer;
    top: 0px;

}

.selectStyle2Mini {
    cursor: pointer;
    height: 30px;
}

.checkBoxTextMini {

    color: #383842;
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-left: 0.4vmax;
}

.BlueButtonWrapperMini {
    margin-left: 15px;
    margin-top: 12px;
    width: 335px;
    height: 40px;
    margin-bottom: 30px;
}

.buttonWrapperMini {
    border-radius: 10px 1px 10px 10px;
    height: 40px;
    width: 335px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
}

.buttonTextMini {
    color: #FFF;
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}

@keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(2rem);
    }
  }
  
  .menu--close {
    animation: fadeOut 0.2s ease-in-out;
  }