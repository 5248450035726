.newsWrapper {
    width: 350px;

    background: #C5E2F1;
    box-shadow: 4px 4px 10px rgba(89, 116, 130, 0.25);
    border-radius: 25px 5px 25px 25px;
    z-index: 1001;
}

.shadow {
    background: black;
    opacity: 0.3;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.shadow2 {
    background: black;
    opacity: 0.6;
    position: fixed;
    z-index: 1002;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.buttonWrapper {
    background: #C5E2F1;
    margin-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 16px;
    cursor: pointer;
}

.buttonWrapper:hover {
    color: whitesmoke;
}

.newsTextWrapper {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */


    color: #201644;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 25px;
    ;
}

.inputFile {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border-radius: 50px;
    width: 100%;
    height: 100%;
}

.cutWrapper {
    z-index: 1006;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
    display: flex;
    align-content: center;
    justify-content: center; 
    align-items: center;
}