.sliderButtonLeft {
    position: absolute;
    left: 20px;
    bottom: -80px;
    cursor: pointer;
    z-index: 2;
    width: 10px;
}

.sliderButtonRight {
    position: absolute;
    right: 20px;
    bottom: -80px;
    cursor: pointer;
    z-index: 1;
}

.pointWrapper {
    width: 100%;
    height: calc(100%);
    background: red;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: end;
    pointer-events: none;
}

.imageStyle {
    transition: 0.3s;
    object-fit: contain;
    transition-property:all;

}

.pointCenter{
    position: absolute;
    width: 100%;
    bottom: -58px;
    cursor: pointer;
    z-index: 1;
    display: flex;
    justify-content: center;
}
