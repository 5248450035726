.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .ReactCrop__image {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
  }
  