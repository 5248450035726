.wrapElement{
    margin: 30px;
}
.imgWrapper{
    height: 130px;
    display: flex;
    align-items: center;
}
.textStyle {
    color: var(--2, #0F0F28);
    /* НЦ Выделение */
    font-family: 'Raleway';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-top: 20px;
    text-align: center;
}