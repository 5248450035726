.justifier{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.contactBackground{
    max-width: 1920px;
    width: 100%;
    background: #F6F6F6;
    position: relative;
    overflow: hidden;
}
.contactTint{
    position: absolute; 
    height: calc(146.956px + 3.8vw);
    width: 100%;
    max-width: 1920px;
    background: rgba(216, 216, 216, 0.4);
}

.contactTextBox{
    display: flex;
    flex-direction: row;
    margin-bottom: 200px;
}

.topLeftText{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    margin-left: 21%;
    margin-top: 4%;
    z-index: 2;
}

.topRightText{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-left: 12.76%;
    margin-top: 4.21%;
    width: 32.968%;
    z-index: 2;
}

.contactCross{
    position: absolute;
    width: calc(205.435px + 61.957vw);
    margin-left: calc(23.37vw - 313.7px);
    margin-top: calc(3.8vw - 11.0435px);
}

.legalContactsBoxWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.legalContactsBox{
    margin-top: 2%;
    margin-bottom: 6%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    width: calc(713.478px + 20.65vw);
}

.lineSeparator{
    width: calc(713.478px + 20.65vw);
    height: 0px;
    border: 1px solid rgba(115, 109, 140, 0.22);
}

.lineWrapper{
    display: flex;
    justify-content: center;
    margin-top: 4%;
}

.legalContactAddress{
    color: #201644;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    width: 50%;
}

.legalContactIds{
    color: #201644;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height:200%;
}

.legalContactSupport{
    color: #201644;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 225%;
}

.legalContactSocials{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    margin-right: 3%;
}

.firstHalfWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 45%;
}

.secondHalfWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 45%;
}

.youtube{
    cursor:pointer;
}

@media(min-width:1920px){
    .contactTint{
        height: 220px;
    }
    .contactCross{
        margin-top: 62px;
        margin-left: 135px;
        width: 1395px;
    }
    .lineSeparator{
        width: 1110px;
    }
    .legalContactsBox{
        width: 1110px;
    }
}

@media(max-width:1000px){
    .contactTint{
        height: calc(249.65px - 6.465vw);
    }
    .topLeftText{
        margin-left: 4%;
        margin-top: 5.3%;
        font-size: 30px;
        line-height: 35px;
    }
    .topRightText{
        margin-left: 3%;
        margin-top: 5.8%;
        width: 63.5%;
        font-size: 16px;
        line-height: 130%;
    }
    .contactCross{
        width: 825px;
        margin-left: calc(-2.16vw - 58.45px);
        margin-top: calc(91.66px - 6.466vw);
    }
    .lineSeparator{
        width: calc(122.845vw - 308.448px);
    }
    .legalContactsBox{
        width: calc(122.845vw - 308.448px);
    }
    .firstHalfWrapper{
        width: 60%;
    }
    .secondHalfWrapper{
        width: 35%;
    }
}

@media(max-width:850px){
    .contactTextBox{
        margin-bottom: 170px;
    }

    .legalContactAddress{
        font-size: 14px;
    }
    
    .legalContactIds{
        font-size: 13px;
    }
    
    .legalContactSupport{
        font-size: 14px;
    }
}

@media(max-width:768px){
    .contactTint{
        height: 200px;
    }
    .contactTextBox{
        flex-direction: column;
    }
    .topLeftText{
        margin-left: 8.72%;
        margin-top: 6.5%;
        font-size: 24px;
        line-height: 28px;
    }
    .topRightText{
        margin-left: 8.72%;
        margin-top: 2.6%;
        width: 82.68%;
        font-size: 14px;
        line-height: 130%;
    }
    .contactCross{
        margin-left: calc(12.5vw - 171px);
        margin-top: calc(42px);
    }
    .lineSeparator{
        width: calc(128.33px + 65.97vw);
    }
    .legalContactsBox{
        width: calc(128.33px + 65.97vw);
    }
}

@media(max-width:650px) {
    .legalContactsBox{
        flex-direction: column;
    }
    .firstHalfWrapper{
        width: 100%;
        margin-bottom: 6%;
    }
    .secondHalfWrapper{
        width: 100%;
    }
    .legalContactIds{
        text-align: right;
    }
    .legalContactSocials{
        margin-top: 2.5%;
        flex-direction: row;
        width: 100px;
        margin-right: 0%;
    }
}

@media(max-width:480px){
    .contactTint{
        height: calc(120px + 16.67vw);
    }
    .topLeftText{
        margin-left: 3.75%;
        margin-top: 9.79%;
        font-size: 20px;
        line-height: 23px;
    }
    .topRightText{
        margin-left: 3.75%;
        margin-top: 5.2%;
        width: 92.7%;
        font-size: 12px;
        line-height: 130%;
    }
    .contactCross{
        margin-left: calc(-2.5vw - 99px);
        margin-top: calc(16vw - 38px);
    }
    .lineSeparator{
        width: calc(5px + 91.67vw);
    }
    .legalContactsBox{
        width: calc(5px + 91.67vw);
    }
}

@media(max-width:400px){
    .firstHalfWrapper{
        flex-direction: column;
    }
    .legalContactIds{
        text-align: left;
        font-size: 12px;
    }
    .legalContactAddress{
        width: 70%;
        font-size: 12px;
        margin-bottom: 5%;
    }
    .legalContactSupport{
        font-size: 12px;
    }
}

@media(max-width:360px){
    .contactTint{
        height: 180px;
    }
    .topLeftText{
        margin-left: 3.61%;
        margin-top: 8.33%;
    }
    .topRightText{
        margin-left: 3.61%;
        margin-top: 6.94%;
        width: 93%;
    }
    .contactCross{
        margin-top: 22px;
    }
    .lineSeparator{
        width: 93%;
    }
    .legalContactsBox{
        width: 93%;
    }
}