.holder{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.wrapper{
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.splash{
    position: absolute;
    margin-top: -85px;
    width: 100%;
    z-index: -1;
    min-height:710px;
}

.trailer{
    width: 60%;
}

.mmco{
    width: 18%;
}

@media (max-width:1600px) {
    .splash {
        min-height:160px;
    }
}

.topLeftTab{
    width: 660px;
    height: 270px;
    background: rgba(216, 216, 216, 0.4);
    border-radius: 0px 5px 25px 0px;
    
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.topLeftTabAligner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 197px;
    margin-top: 31px;
    margin-right: 57px;
}

.topLeftTabHeader{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.4487179487179487vw + 11.384615384615385px);
    line-height: 23px;
    cursor: pointer;
}

.topLeftTabHeader:hover {
    color: #808080;
}

.topLeftTabText{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: calc(0.4487179487179487vw + 11.384615384615385px);
    line-height: 23px;
    cursor: pointer;
}

.topLeftTabText:hover {
    color: #808080;
}

.topTabs{
    margin-top: 145px;
    display: flex;
    flex-direction: row;
}

.topRightTab{
    width: 825px;
    background: rgba(246, 246, 246, 0.75);
    border-radius: 25px;
    margin-left: 30px;
    z-index: 2;
}

.topRightTabHeader{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.8974358974358974vw + 14.76923076923077px);
    line-height: 38px;
    margin-left: 47px;
    margin-top: 25px;
}

.topRightTabText{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: calc(0.2564102564102564vw + 11.076923076923077px);
    line-height: 130%;
    display: inline-block;
    justify-content: center;
    margin-left: 47px;
    margin-right: 48px;
    margin-top: 30px;
    margin-bottom: 25px;
    flex-direction: column;
    text-align: justify;

}

.achievementHeader{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.8974358974358974vw + 14.76923076923077px);
    line-height: 38px;
    margin-left: 40px;
    color: #201644;
}

.achievementHolder{
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 50px;
}

@media (max-width:680px) {
    .achievementHolder {
        justify-content: center;
    }
}

.trailer{
    width: 600px;
}

.mmco{
    width: 180px;
}

@media (max-width:1920px) {
    .trailer{
        width: 60%;
    }
    
    .mmco{
        width: 18%;
    }
}




.achievementBlock{
    width: calc(4.294871794871795vw + 104.53846153846153px);
    height: calc(2.1153846153846155vw + 82.38461538461539px);
    background: rgba(197, 226, 241, 0.3);
    box-shadow: 2px 2px 8px rgba(89, 116, 130, 0.25);
    border-radius: 25px 5px 25px 25px;
    margin-right: 25.55px;
    margin-bottom: 30px;
    margin-left: 10px;
}

.achievementBlockHeader{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: calc(1.0256410256410256vw + 12.307692307692307px);
    line-height: 38px;
    text-align: center;
    margin-top: 15px;
    color: #201644;
}

.achievementBlockText{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.19230769230769232vw + 12.307692307692308px);
    line-height: 130%;
    text-align: center;
    margin-top: calc(0.5128205128205128vw - 1.8461538461538463px);
    color: #201644;
}

.tabMargin {
    margin-left:calc(28.125vw + 150px);
}
@media (max-width:768px) {
    .tabMargin{
        margin-left: 20px;
    }
}
.certificateSidebar{
    z-index: 10;
    padding-right: 37px;
    width: 20px;
}

.certificateHolder{
    margin-top: 45px;
    display: flex;
    flex-direction: row;
}

.certificateWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.certificateHeader{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.2564102564102564vw + 11.076923076923077px);
    line-height: 130%;
    margin-top: 15px;
    color: #201644;
}

.certificateScroller{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin-top: 55px;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.certificateScroller::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.certificateScrollButtons{
    margin-left: calc(34.72222222222222vw - 166.66666666666663px);
    margin-top: 50px;
}
@media (max-width:768px) {
    .certificateScrollButtons{
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }
}

.scrollLeft{
    margin-right: 20px;
    cursor: pointer;
}

.scrollRight{
    margin-left: 20px;
    cursor: pointer;
}

.partnersWrapper{
    margin-top: 140px;
}

.partnersHeader{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: calc(0.8974358974358974vw + 14.76923076923077px);
    line-height: 38px;
    margin-left: 47px;
    color: #201644;
}

.partnersBox{
    margin-bottom: 70px;
}

@media (max-width:480px) {
    .partnersWrapper{
        display: flex;
        justify-content: center;
    }
}

.adoptiveMenuWrap{
    margin-top: 10px;
    height: 20px;
    background: rgba(216, 216, 216, 0.4);
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

@media (max-width:359px) {
    .splash{
        height: 250px;
    }
    .adoptiveMenuWrap{
        display: flex;
        flex-direction: column;
        height: 100px;
        text-align: center;
    }
}