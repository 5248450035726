.justifier{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.newsBackground{
    background-color: #F6F6F6;
    max-width: 1920px;
    width: 100%;
}

.pathToPage {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    display: flex;
    align-items: center;

    color: #0092DD;
}


.newsBox{
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(39.478vw - 354.78px);
    width: calc(730.435px + 21.96vw);
    z-index: 2;
    color: #201644;
}

.topTextBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 3%;
    max-width: 1920px;
    width: 100%;
    color: #201644;
}

.topLeftText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #201644;
    margin-top: 3.7%;
    margin-left: calc(39.13vw - 331.3px);
    width: 450px;
    z-index: 2;
}

.topRightText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-top: 3.7%;
    width:600px;
    color: #201644;
    z-index: 2;
    margin-right: 1%;
}


.newsTint{
    background: rgba(216, 216, 216, 0.4);
    height: calc(99.13px + 13.587vw);
    width: 100%;
    position: absolute;
    z-index: 1;
    max-width: 1920px;
}

.newsCross{
    position: absolute;
    z-index: 2;
    width: calc(210.869px + 61.41vw);
    height: calc(59.304px + 13.37vw);
    margin-top: calc(73.78px + 6.52vw);
    scale: 100%;
    max-width: 1920px;
}

.loadMore{
    width: 94%;
    cursor: pointer;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-decoration-line: underline;
    text-align: center;
    color: #0092DD;
    margin-top: 5%;
    margin-bottom: 5%;
}


@media(min-width:1920px){
    .newsTint{
        height: 360px;
    }
    .newsCross{
        margin-top: 205px;
        width: 1390px;
        height: 316px;
    }
    .topLeftText{
        margin-left: 420px;
    }
    .newsBox{
        margin-left: 21%;
        width: 60%;
    }
}

@media(max-width: 1000px){
    .newsBox{
        width: calc(85% + 10vw);
        margin-left: 4%;
    }
    .topTextBox{
        margin-bottom: 4%;
    }
    .topLeftText{
        font-size: 30px;
        line-height: 35px;
        width: calc(101.379px + 25.86vw);
        margin-left: 7%;
    }
    .topRightText{
        font-size: 16px;
        line-height: 130%;
        width: 50%;
    }
    .newsTint{
        height: calc(25.86vw - 23.62px);
    }
    .newsCross{
        margin-top: calc(19.396vw - 53.965px);
    }
}

@media(max-width: 768px){
    .newsBox{
        width: 95%;
        margin-left: 5%;
    }
    .loadMore{
        font-size: 16px;
        line-height: 19px;
    }
}

@media(max-width: 700px){
    .topTextBox{
        margin-bottom: 12%;
    }
    .topLeftText{
        font-size: 18px;
        line-height: 21px;
        margin-left: 4%;
        width: 35%;
        color: #201644;
    }
    .topRightText{
        font-size: 12px;
        line-height: 130%;
        width: 55%;
        color: #201644;
    }
    .newsTint{
        height: calc(108.33px + 8.68vw);
    }
    .newsCross{
        margin-top: calc(82.454px + 1.364vw);
        margin-left: -20px;
        width: 100vw;
    }
}

@media(max-width: 600px){
    .newsBox{
        width: 95%;
        margin-left: 2.5%;
        color: #201644;
    }
    .loadMore{
        font-size: 14px;
        line-height: 16px;
    }
}

@media(max-width: 440px){
    .topTextBox{
        display: flex;
        flex-direction: column;
        color: #201644;
    }
    .topLeftText{
        margin-top: 8%;
        margin-left: 5%;
        width: 95%;
        color: #201644;
    }
    .topRightText{
        margin-top: 4%;
        width: 95%;
        margin-left: 5%;
        color: #201644;
    }
    .newsCross{
       display: none;
    }
    .newsTint {
        height: calc(713.5px - 128.75vw);
    }
    .loadMore{
        font-size: 12px;
        line-height: 14px;
    }
}

.shadow {
    background: rgb(0, 0, 0, 0.6);
    /* opacity: 0.6; */
    position: fixed;
    z-index: 1010;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalWrapper {
    width: 500px;
    height: 200px;
    background: white;
    border-radius: 16px;
}
.modalText {
    margin-top: 10px;
    text-align: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    color: #201644;
}
.alertButtonWrapperDelete {
    color: #0092DD;
    border: 1px solid #0092DD;
    border-radius: 16px;
    padding: 10px;
    width: 150px;
    text-align: center;
    margin-left: 50px;
    margin-top: 30px;
    cursor: pointer;
}
.alertButtonWrapperCancel {
    cursor: pointer;
    margin-top: 30px;
    color: #0092DD;
    border: 1px solid #0092DD;
    border-radius: 16px;
    padding: 10px;
    width: 150px;
    text-align: center;
    margin-right: 50px;
}

.plusStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
}

.wrapProd2 {
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(89, 116, 130, 0.15);
    width: 260px;
    border-radius: 15px 4px 15px 15px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    margin-top: 10px;
}

.wrapProd2:last-child {
    margin-right: 0px;
}
.createButtonWrapper {
    background: #0092DD;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
    border-radius: 100px;
    cursor: pointer;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.inputStyle {
    Border: none;
    outline: none;
    margin-top: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 17px */
    display: flex;
    align-items: center;
    color: rgba(32, 22, 68, 0.8);

}
.labName {

    margin-top: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 17px */
    display: flex;
    align-items: center;
    color: rgba(32, 22, 68, 0.8);
}
.labDescription {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

}
.inputDescriptionStyle {
    Border: none;
    outline: none;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* accent */

}
