.toolbar{
    border-radius: 10px;
background: #ffffff;
box-shadow:  -8px 8px 13px #ededed,
             8px -8px 13px #ffffff;
    margin:1% 0;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    padding: 15px 10px;
    row-gap: 15px;
  top: 0;
  z-index: 2;
  width: 100%;
  margin-top: 2%;
}
.toolbarGrp>*{
    margin-right: 10px;
    cursor: pointer;
}
.toolbarGrp{
    margin:0 10px;
    display: flex;
    flex-direction: row;
}
select{
    height: 30px;
    border: none;
    width: 6.9rem;
}