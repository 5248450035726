.productTextHeader {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #201644;
    text-decoration: none;
}
.link {
    color: #201644;
    text-decoration: none;
}

.link:hover {
    color: #201644;
    text-decoration: none;
}

.link:active {
    color: #201644;
    text-decoration: none;
}