.SliderWrapper {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.mainImg{
    user-select: none;
}

.wrapper {
    position: relative;
}

.leftButtonStyle {
    position: absolute;
    top: calc(50% - 25px);
    left: 10px;
    z-index: 1;
    cursor: pointer;
}

.rightButtonStyle {
    position: absolute;
    top: calc(50% - 25px);
    right: 10px;
    z-index: 1;
    cursor: pointer;
}

.sliderText {
    position: absolute;
    z-index: 2;
    left: calc(30px + 10%);
    top: 10%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: calc(12px + 20 * (100vw / 1280));
    line-height: calc(12px + 20 * (100vw / 1280) + 5px);
    color: #FFFFFF;
    width: 30%;
    min-width: 180px;
    cursor: default;
}

.shading {
    z-index: 1;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: calc(100% - 5px);
}

.sliderText {
    /* background: rgba(242, 242, 242, 0.4);
    backdrop-filter: blur(1px); */
    /* position: relative;
    
    
    transition: 0.5s; */
}

.newSliderText{
    background: rgba(242, 242, 242, 0.4);
    backdrop-filter: blur(1px);
    transition: 0.5s; 
    padding-top: 20px;
    padding-bottom: 20px;


}

.subWrap{
    margin-top: 64px;
    position: absolute;
    left: 0;
    top: 64px;
    transition: 0.5s;
    height: 431px;
    z-index: 2;
    overflow: hidden;
}

.slideHeaderText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 45px;
    color: #201644;
    width: 550px;
    margin-left: 20vw;
    margin-bottom: 2%;
}

.slideHeader2Text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #201644;
    width: auto;
    margin-left: 20vw;
    margin-bottom: 2%;
}

.slideHeaderTextNotAdaptive {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 45px;
    color: #201644;
    width: 550px;
    margin-left: 500px;
    margin-bottom: 20px;
}

.slideHeader2TextNotAdaptive {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #201644;
    width: 370px;
    margin-left: 500px;
    margin-bottom: 20px;
}

.buttonWrap {
    margin-left: 20vw;
    margin-top: 20px;
    width: 280px;
}

.buttonWrapNotAdaptive {
    margin-left: 500px;
    margin-top: 20px;
}



@media (max-width:768px) {
    .buttonWrap {
        margin-left: 20vw;
        margin-top: 20px;
    }

    .slideHeaderText {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #201644;
        width: 400px;
        margin-left: 20vw;
        margin-bottom: 5%;
    }

    .slideHeader2Text {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        color: #201644;
        width: 400px;
        margin-left: 20vw;
        margin-bottom: 5%;
    }
}



@media (max-width:650px) {

    .subWrap{
        margin-top: 0;
    }
    
    .buttonWrap {
        margin-left: 20vw;
        margin-top: 20px;
    }
    .sliderText {
        height: 250px;
        background: rgba(242, 242, 242, 0.4);
        backdrop-filter: blur(1px);
        z-index: 2;
        position: absolute;
        width: 100%;
        left: 0;
        margin-top: 10px;
     
    }

    .slideHeaderText {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #201644;
        width: 240px;
        margin-left: 20vw;
        margin-bottom: 2%;
    }

    .slideHeader2Text {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: #201644;
        width: 240px;
        margin-left: 20vw;
        margin-bottom: 2%;
    }
}

@media (max-width:480px) {
    .buttonWrap {
        margin-left: 15vw;
        margin-top: 20px;
    }
    .slideHeaderText {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #201644;
        width: 240px;
        margin-left: 15vw;
        margin-bottom: 2%;
    }

    .slideHeader2Text {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: #201644;
        width: 240px;
        margin-left: 15vw;
        margin-bottom: 2%;
    }

}