blockquote{
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
table, th, td {
    border: 1px solid black;
}
table{
    border-collapse: collapse;
}
button{
    background-color: white;
    border:none;
    opacity: 0.5;
}

.editor div{
    font-size: 16px;
    font-family: Raleway;
    font-weight: 500;
    line-height: 130%;
}
.dragOverlayContent div{
    font-size: 16px;
    font-family: Raleway;
    font-weight: 500;
    line-height: 130%;
}

.editorWrapper{
    font-family:'Helvetica','Arial', sans-serif;
    border-radius: 10px;
    background: #ffffff;
    box-shadow:  -8px 8px 13px #ededed,
                8px -8px 13px #ffffff;
    min-height: 400px;
    min-width: 100%;
    width: fit-content;
    height: fit-content;
    padding: 12px 10px;
    margin-bottom: 2%;
}
table{
    width:100%;
}
td{
    height: 50px;
    padding:0 5px;
}
button{
    cursor: pointer;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
[data-slate-node="element"] {
    margin: 10px 0;
}
html{
    scroll-behavior: smooth;
}

.sortable {
    display: flex;
    width: 100%;
    transform: translate3d(0, var(--translate-y, 0), 0);
    z-index: 0;
  }
  
  .sortable > div {
    padding: 10px 0;
    flex: 1;
  }