.justifier{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.innerJustifier{
    width: 100%;
    max-width: 1920px;
}

.newsPadding{
    padding-left: 23%;
}

.newsScrollerText{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    margin-left: 27%;
    margin-top: 5%;
    margin-bottom: 1vw;
}

.newsScroller{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
    scroll-behavior: smooth;
    height: 70%;
    scrollbar-width: none;
}

.newsScroller::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.scrollButtons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-right: 20%;
}
.scrollLeft{
    cursor: pointer;
    margin-right: 2vw;
}
.scrollRight{
    cursor: pointer;
}

@media(min-width:1920px){
    .newsScroller{
        height: 70%;
    }
}

@media(max-width:1000px){
    .scrollLeft{
        margin-right: 4vw;
    }
    .newsScrollerText{
        margin-bottom: 3vw;
    }
    .newsPadding{
        padding-left: 11.5%;
    }
    
}

@media(max-width:768px){
    .newsScrollerText{
        margin-bottom: 4vw;
    }
}

@media(max-width:480px){
    .scrollButtons{
        justify-content: center;
        margin-left: 30%;
    }
    .scrollLeft{
        margin-right: 10vw;
    }
    .newsScrollerText{
        text-align: center;
        font-size: 18px;
        line-height: 21px;
        margin-left: 0%;
    }
}

@media(max-width:360px){

}